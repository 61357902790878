import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { MethodIconComponent } from '@app/components/shared/method-icon/method-icon.component';
import { Api, ApiRequest } from '@app/models/speedpost-data.model';
import { Server } from '@app/models/openapispec.model';
import { SharedService } from '@app/services/speedpost-api/shared.service';

export interface KeyValuePair {
  key: string;
  value: string;
}

@Component({
    selector: 'app-production-tab',
    standalone: true,
    templateUrl: './production-tab.component.html',
    styleUrl: './production-tab.component.scss',
    imports: [
    FormsModule,
    ReactiveFormsModule,
    MethodIconComponent,
    CommonModule,
    HttpClientModule,
    BsDropdownModule
]
})
export class ProductionTabComponent implements OnChanges, AfterViewInit {
  @Input() activeAPIObj: Api = null as unknown as Api;
  @Input()
  prodRequest!: ApiRequest;
  @Input()
  productionTabDetails!: { key: string | undefined; secretId: string | undefined};
  @Input()
  allTabsFormValidForProd: boolean = true;
  @Input() servers: Server[] = null as unknown as Server[];
  keyValues: KeyValuePair[] = [];
  @Output() emitResponseEvent = new EventEmitter();
  isInputFocused: boolean = false;
  selectedUrl: string='';
  selectedPath: string = '';
  @Input() addedParams!: {param: {key:string, value: string}[], paramType: string};
  @Input()
  isParamForm!: boolean;
  @Input()
  queryParamForm: boolean = true;
  @Input()
  pathParamForm: boolean = true;
  @Input()
  headerForm: boolean = true;
  @Input()
  bodyForm: boolean = true;
  @Output() isLoadingEvent = new EventEmitter();

  constructor(private sharedService: SharedService, private cdref: ChangeDetectorRef){
  }
  ngAfterViewInit(): void {
    this.cdref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setDefaultValueIfFormNotReceived(changes);
    if(changes['addedParams'] && changes['addedParams'].currentValue?.paramType === 'query'){
      if(changes['addedParams'].currentValue?.param.length > 0){
        this.selectedPath = this.activeAPIObj.path;
        let addedParam = '';
        const queryParams = changes['addedParams'].currentValue?.param
          .filter((pair: { key: string; value: string | number; }) => pair.key && pair.value)
          .map((pair: { key: string; value: string | number; }) => {
            const cleanedKey = pair.key.includes('*(required)') 
              ? pair.key.replace(/\*\(required\)/, '') 
              : pair.key;
            return `${encodeURIComponent(cleanedKey)}=${encodeURIComponent(pair.value)
            }`})
          .join('&');
        if (queryParams) {
          addedParam += `?${queryParams}`;
        }
        this.selectedPath += addedParam;
      } else {
        this.selectedPath = this.activeAPIObj.path;
      }
    } else if(changes['addedParams'] && changes['addedParams'].currentValue?.paramType === 'path'){
      if(changes['addedParams'].currentValue?.param.length > 0){
        this.selectedPath = this.sharedService.buildUrl(this.activeAPIObj.path, changes['addedParams'].currentValue?.param);
      } else {
        this.selectedPath = this.activeAPIObj.path;
      }
    } else {
      this.selectedPath = this.activeAPIObj.path;
    }
    this.selectedUrl = this.activeAPIObj.url[0].url;
    this.cdref.detectChanges();
  }  

  setDefaultValueIfFormNotReceived(changes: SimpleChanges){
    if(changes && changes['pathParamForm'] === undefined){
      this.pathParamForm = true;
    }
    if(changes && changes['queryParamForm'] === undefined){
      this.queryParamForm = true;
    }
  }

  getInitialHeaders(){
    const keyValues: { key: string; value: string; }[] = [];
    if(this.prodRequest?.headers) {
      this.prodRequest.headers.forEach(param => {
        keyValues.push({
          key: `${param.name}`,
          value: param.example? param.example : (param.examples && this.prodRequest.selectedExampleKey? param.examples[this.prodRequest.selectedExampleKey].value : '')
        });
      });
    }
    return keyValues;
  }

  getInitialParams(){
    const keyValues: { key: string; value: string; }[] = [];
    if(this.prodRequest && this.prodRequest.parameters){
      this.prodRequest.parameters.forEach(param => {
        keyValues.push({
          key: `${param.name}`,
          value: param.example? param.example : (param.examples && this.prodRequest.selectedExampleKey? param.examples[this.prodRequest.selectedExampleKey].value : '')
        });
      });
    }
    return keyValues;
  }

  getInitialAuthorizationData(){
    if(this.productionTabDetails){
      return btoa(`${this.productionTabDetails.key}:${this.productionTabDetails.secretId}`);
    } else {
      return this.productionTabDetails;
    }
  }

  onExecute(){
    this.isLoadingEvent.emit(true);
    const formData = this.sharedService.getAllTabDetails();
    const requestData = {
      apiMethod: this.activeAPIObj?.method,
      productionUrl: `${this.selectedUrl}${this.activeAPIObj?.path}`,
      authorization: formData && formData.authorization ? formData.authorization : this.getInitialAuthorizationData(),
      queryParameters: formData && formData.queryParams ? formData.queryParams : [],
      pathParameters: formData && formData.pathParams ? formData.pathParams : [],
      headers: formData && formData.headers ? formData.headers : this.getInitialHeaders(),
      body: formData && formData.body ? formData.body : '',
    };
    this.sharedService.getContractDetailsResponse(requestData).subscribe({
      next: (response) => {
        this.isLoadingEvent.emit(false);
        this.emitResponseEvent.emit(response);
      },
      error: (error: Error) => {
        this.isLoadingEvent.emit(false);
        this.emitResponseEvent.emit(error);
      }
    });
  }

  updateSelectedExample(server: string) {
    this.selectedUrl= server;
  }

  updateSelectedExampleKeyDown(event: KeyboardEvent, server: string){
    if (event.key === "Enter") {
      this.updateSelectedExample(server);
    }
  }

  areAllFormsValid(): boolean{
    return !(this.headerForm && this.bodyForm && this.queryParamForm && this.allTabsFormValidForProd);
  }
}
