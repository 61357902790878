<div class="sidebar-wrapper h-100">
    <div class="sidebar">
        <div *ngIf="!(groupsArray.length === 0);">
            <accordion [closeOthers]="oneAtATime" [isAnimated]="true">
                <div class="endpoints">
                    <accordion-group [heading]="group.groupName" [isOpen]="activeGroup === group.groupName" *ngFor="let group of groupsArray">
                        <ul role="menu" aria-labelledby="button-animated" >
                            <ng-container *ngFor="let api of group.endpoints">
                                <li role="menuitem" class="d-inline-block" [ngClass]="api.active? 'active' : ''">
                                    <button class="dropdown-item"  (click)="updateActiveAPI(api)">
                                        <app-method-icon [methodName]="api.method" sizeCategory="sidebar"></app-method-icon><span class="d-inline-block sidebar-item fs__14 text__bold">{{api.summary? api.summary : api.apiDetails?.summary? api.apiDetails ?.summary : api.path}}</span>
                                    </button>
                                </li>
                                <button *ngIf="api.apiDetails?.description" class="info d-inline-block" (click)="openModalPopup(api)">{{''}}</button>
                            </ng-container>
                        </ul>
                    </accordion-group>
                </div>
            </accordion>
        </div>
        <!-- <div class="favourite-subscription">
            <accordion-group heading="Favourite APIs" class="favourite">
                <ul role="menu" aria-labelledby="button-animated">
                    <li role="menuitem"><button><app-method-icon methodName="get" sizeCategory="sidebar"></app-method-icon>API Name</button></li>
                    <li role="menuitem"><button><app-method-icon methodName="del" sizeCategory="sidebar"></app-method-icon>API Name</button></li>
                </ul>
            </accordion-group>
            <accordion-group heading="Subscription" class="subscription">
                <ul role="menu" aria-labelledby="button-animated">
                    <li role="menuitem"><button><app-method-icon methodName="put" sizeCategory="sidebar"></app-method-icon>API Name</button></li>
                    <li role="menuitem"><button><app-method-icon methodName="get" sizeCategory="sidebar"></app-method-icon>API Name</button></li>
                    <li role="menuitem"><button><app-method-icon methodName="post" sizeCategory="sidebar"></app-method-icon>API Name</button></li>
                </ul>
            </accordion-group>
        </div> -->
       
    </div>
</div>