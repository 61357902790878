import { Component, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import {
  BsModalRef,
  BsModalService,
  ModalModule,
  ModalOptions,
} from 'ngx-bootstrap/modal';

import { Api, EndpointGroupsModal } from '@app/models/speedpost-data.model';
import { ModalPopupComponent } from '@app/components/shared/modal-popup/modal-popup.component';
import { MethodIconComponent } from '@app/components/shared/method-icon/method-icon.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    MethodIconComponent,
    ModalModule
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  providers: [BsModalService]
})
export class SidebarComponent implements OnChanges {
  @Input() apisArray: Api[] = [];
  @Output() updateActiveAPIEvent = new EventEmitter<Api>();

  bsModalRef?: BsModalRef;
  oneAtATime = true;
  activeGroup: string = "";
  groupsArray: EndpointGroupsModal[] = [];

  constructor(
    public modalService: BsModalService,
  ){}

  ngOnChanges(): void {
    this.groupsArray = Object.values(this.apisArray.reduce((acc: { [key: string]:  EndpointGroupsModal}, obj) => {
      const tag = obj.tag || 'default';
      if (!acc[tag]) {
          acc[tag] = { groupName: tag, endpoints: [] };
      }
      acc[tag].endpoints.push(obj);
      return acc;
    }, {}));
  }

  updateActiveAPI(value: Api) {
    this.updateActiveAPIEvent.emit(value);
  }

  openModalPopup(api: Api) {
    const title = (api.summary? api.summary : api.apiDetails?.summary? api.apiDetails?.summary : api.path) + ' - Description';
    const initialState: ModalOptions = {
      class: 'modal-dialog-centered modal-lg i-modal',
      initialState: {
        title,
        data: api.apiDetails?.description,
      },
      ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(
      ModalPopupComponent,
      initialState,
    );
  }
}
