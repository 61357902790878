<ng-container>
    <div class="mb-5" *ngIf="showResponse">
        <p class="d-inline-block vertical-align-sub fs__16 text__bold">Response</p>
        <tabset  class="tabset">
            <tab *ngFor="let response of responses" [id]="response.statusCode">
                <ng-template tabHeading><div class="heading text__medium fs__16">{{response.statusCode}}<button class="info d-inline-block" *ngIf="response.description || response.description === null" (click)="openModalPopup(response.statusCode, response.description)">{{''}}</button></div></ng-template>
                <div class="mb-1" *ngIf="response.mediaTypes">
                    <div *ngIf="response.mediaTypes.length > 1; else mediaTypeSingle">
                        <div class="mb-2 text__medium">Content Type:</div>
                        <div class="position-relative cursor-pointer dropdown" dropdown dropdownToggle>
                            <div class="content-type tablet-element list mb-2" >{{response.selectedMediaType}} <span class="caret"></span></div>
                            <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu"
                                role="menu" aria-labelledby="button-animated">
                                <li role="menuitem" tabindex="0" *ngFor="let mediaType of response.mediaTypes" (click)="updateSelectedMediaType(response.statusCode, mediaType)" 
                                (keydown)="updateSelectedMediaTypeKeyDown($event, response.statusCode, mediaType)" class="dropdown-item p-2 ">{{mediaType}}</li>
                            </ul>
                        </div>
                    </div>
                    <ng-template #mediaTypeSingle><div class="mb-2 text__medium">Content Type:</div><div class="content-type tablet-element mb-2">{{response.selectedMediaType}}</div></ng-template>
                </div>

                <div class="mb-1" *ngIf="showResponseTab(response)">
                    <div *ngIf="response.examples">
                        <div class="mb-2 text__medium">Example:</div>
                        <div class="position-relative cursor-pointer dropdown" dropdown dropdownToggle>
                            <div class="tablet-element example list  mb-2" >{{response.selectedExampleKey}} <span class="caret"></span></div>
                            <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu"
                                role="menu" aria-labelledby="button-animated">
                              <li role="menuitem" tabindex="0" *ngFor="let exampleKey of response.exampleKeys" (click)="updateSelectedExample(response.statusCode, exampleKey)" 
                              (keydown)="updateSelectedExampleKeyDown($event, response.statusCode, exampleKey)" class="dropdown-item p-2 ">{{exampleKey}}</li>
                            </ul>
                        </div>
                    </div>

                    <tabset>
                        <tab id="headers" *ngIf="response.headers">
                            <ng-template tabHeading><div class="heading text__medium fs__16">Headers</div></ng-template>
                            <table class="w-100 param-table">
                                <thead>
                                    <tr>
                                        <th>Key</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let header of objectKeys(response.headers)">
                                        <td><input type="text" placeholder="{{header}} {{response.headers[header].required ? '*(required)' : ''}}" class="w-100 form-control" disabled></td>
                                        <td><input type="text" [placeholder]="getExample(header, response)" 
                                            class="w-100 form-control" disabled></td>
                                    </tr>
                                </tbody>
                            </table>
                        </tab>
                        <tab id="body" *ngIf="response.selectedMediaType && response.examples && response.selectedExampleKey" class="position-relative">
                            <ng-template tabHeading><div class="heading text__medium fs__16">Body<button class="info d-inline-block" (click)="openModalPopup('Response', response.description)">{{''}}</button></div></ng-template>
                            <app-code-editor [content]="response.examples[response.selectedExampleKey].value" [mediaType]="response.selectedMediaType" [inputDisabled]="true"></app-code-editor>
                            <img class="download-icon cursor-pointer" src="/assets/images/download-icon.svg" [ngClass]="{'disabled': !response.examples[response.selectedExampleKey].value}"
                                (click)="downloadContent(response.statusCode, response.selectedMediaType, response.examples[response.selectedExampleKey].value)"
                                (keydown)="downloadContentKeyDown($event, response.statusCode, response.selectedMediaType, response.examples[response.selectedExampleKey].value)"
                                alt="download-icon" tabindex="0"/>
                        </tab>
                        <tab id="body" *ngIf="!response.selectedExampleKey && response.selectedMediaType && response.responseDetails.content" class="position-relative">
                            <ng-template tabHeading><div class="heading text__medium fs__16">Body<button class="info d-inline-block" (click)="openModalPopup('Response', response.description)">{{''}}</button></div></ng-template>
                            <app-code-editor [content]="response.responseDetails.content[response.selectedMediaType].example" [mediaType]="response.selectedMediaType" [inputDisabled]="true"></app-code-editor>
                            <img class="download-icon cursor-pointer" src="/assets/images/download-icon.svg" [ngClass]="{'disabled': !response.responseDetails.content[response.selectedMediaType].example}"
                                (click)="downloadContent(response.statusCode, response.selectedMediaType, response.responseDetails.content[response.selectedMediaType].example)"
                                (keydown)="downloadContentKeyDown($event, response.statusCode, response.selectedMediaType, response.responseDetails.content[response.selectedMediaType].example)"
                                alt="download-icon" tabindex="1"/>
                        </tab>
                    </tabset>

                </div>
                <!-- <ng-template #noExample><div class="no-example p-3">No examples available to display. Please update the contract to view static responses.</div></ng-template> -->

                <ng-container *ngIf="showResponseWarning(response)"><div class="no-example p-3">No examples available to display.</div></ng-container>
            </tab>
        </tabset>    
    </div>
</ng-container>

