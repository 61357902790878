import { Example, Header, Operation, Parameter, Response, SecurityRequirement, SecurityScheme, Server } from "./openapispec.model";

export interface SpeedPostContentModel {
  subscriptions: Array<ProductCredentialsModel>;
  productionSubscribed: boolean;
  apiName: string;
  apiVersion: string;
  type: ApiContentTypeEnum;
  apiSpecificationType: ApiSpecificationTypeEnum;
  fileExtension: string;
  contractType: string;
  fileContent: string;
}

export enum ApiContentTypeEnum {
  ENDPOINTS = 'ENDPOINTS',
  FAVOURITES = 'FAVOURITES',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
}

export enum DigitalContentTypeEnum {
  API = 'API',
  CODE_SNIPPET = 'CODE_SNIPPET',
  EVENTS = 'EVENTS',
}

export enum ApiSpecificationTypeEnum {
  OAS2 = 'OAS2',
  OAS3 = 'OAS3',
  GRAPHQL = 'GRAPHQL',
  RAML = 'RAML',
  ASYNC = 'ASYNC',
  WSDL = 'WSDL'
}

export interface Api {
  path: string;
  method: string;
  apiDetails: Operation | null;
  tag: string;
  active: boolean;
  summary: string | null;
  url: Server[];
  securityScheme: { [securitySchemeName: string]: SecurityScheme }
  security: SecurityRequirement[];
}

export interface ApiRequest {
  description?: string;
  parameters?: Parameter[] | null;
  headers?: Parameter[] | null;
  bodyMediaType: string;
  bodyExampleExists: boolean;
  exampleKeys: string[] | null;
  bodyExamples?: { [mediaType: string]: Example; } | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bodyExample?: any;
  selectedExampleKey: string | null;
  security?: SecurityRequirement[];
  bodyDescription?: string;
}

export interface ApiResponse {
  statusCode: string;
  description: string;
  responseDetails: Response;
  mediaTypes: string[] | null;
  selectedMediaType: string | null;
  exampleExists: boolean;
  exampleKeys: string[] | null;
  examples?: { [mediaType: string]: Example; } | null;
  selectedExampleKey: string | null;
  headers?: { [headerName: string]: Header };
}

export interface ApiModel {
  staticUrl: string;
  dynamicUrl: string;
}

export interface ApiRequestModel {
  apiMethod: string;
  productionUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authorization?: any | null;
  queryParameters?: {key: string, value: string}[];
  pathParameters?: {key: string, value: string}[];
  headers?: {key: string, value: string}[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
}

export interface HttpAuthType {
  formData: { username: string | null, password: string | null } | null;
  authType: string;
}

export interface ApiKeyAuthType {
  formData: { key: string | null, value: string | null, type: string | null } | null;
  authType: string;
}

export interface ProductCredentialsModel {
  clientId: string;
  clientSecret: string;
  name: string;
}

export interface EndpointGroupsModal {
  groupName: string;
  endpoints: Api[];
}